




































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Concejo extends Vue {
  private win = window;
  private base = window.location;
  private menu = "";
  private menuLateral: any = [];
  private nombreWeb: any = "";
  private listaConcejales = [
    {
      nombre: "JUAN CARLOS CHINGA PALMA",
      telefono: "432380238",
      nombreAsistente: "DINA QUEZADA DURAN",
      telefonoAsistente: "432380238",
      email: "juan.chinga@municipalidadarica.cl",

      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2023/02/cconcejo-chinga.png",
    },
    {
      nombre: "DOLORES CAUTIVO AHUMADA",
      telefono: "432380284",
      nombreAsistente: "SEBASTIAN CARCAMO RIVERA",
      telefonoAsistente: "432380284",
      email: "dolores.cautivo@municipalidadarica.cl",

      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2023/02/cconcejo-dolores.png",
    },
    {
      nombre: "CRISTIAN RODRIGUEZ SANHUEZA",
      telefono: "432380229",
      nombreAsistente: "ALEJANDRA CAMPILLAY ESPINOZA",
      telefonoAsistente: "432380229",
      email: "cristian.rodriguez@municipalidadarica.cl",

      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2023/02/cconcejo-rodriguez.png",
    },
    {
      nombre: "DANIEL CHIPANA CASTRO",
      telefono: "432380231",
      nombreAsistente: "SOFIA ALVAREZ VARGAS",
      telefonoAsistente: "432380231",
      email: "daniel.chipana@municipalidadarica.cl",

      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2023/02/cconcejo-chipana.png",
    },
    {
      nombre: "JORGE MOLLO VARGAS",
      telefono: "432380233",
      nombreAsistente: "DANIELA VILLABLANCA DIAZ",
      telefonoAsistente: "432380233",
      email: "jorge.mollo@municipalidadarica.cl",

      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2023/02/cconcejo-mollo.png",
    },
    {
      nombre: "CAROLINA MEDALLA ALCAYAGA",
      telefono: "432380230",
      nombreAsistente: "SALOMÉ LIZAMA CABALLERO",
      telefonoAsistente: "432380230",
      email: "carolina.medalla@municipalidadarica.cl",

      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2023/02/cconcejo-medalla.png",
    },
    {
      nombre: "MARIO MAMANI FERNANDEZ",
      telefono: "432380289",
      nombreAsistente: "CRISHNA MORAGA MEDERO",
      telefonoAsistente: "432380289",
      email: "mario.mamani@municipalidadarica.cl",

      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2023/02/cconcejo-mamani.png",
    },
    {
      nombre: "NINOSKA GONZALEZ FLORES",
      telefono: "432380235",
      nombreAsistente: "PAUL GONZALEZ CARRASCO",
      telefonoAsistente: "432380235",
      email: "ninoska.gonzalez@municipalidadarica.cl",

      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2023/02/cconcejo-ninoska.png",
    },
    {
      nombre: "GABRIEL FERNANDEZ CANQUE",
      telefono: "432380232",
      nombreAsistente: "JAEL CORTES CASTILLO",
      telefonoAsistente: "432380232",
      email: "gabriel.fernandez@municipalidadarica.cl",

      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2023/02/cconcejo-fernandez.png",
    },
    {
      nombre: "MAX SCHAUER FERNANDEZ",
      telefono: "432380234",
      nombreAsistente: "BRUNO ROJAS LATIN",
      telefonoAsistente: "432380234",
      email: "max.schauer@municipalidadarica.cl",

      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2023/02/cconcejo-max.png",
    },
    {
      nombre: "SECRETARIA DE CONCEJALES",
      telefono: "432380269",
      nombreAsistente: "FANNY SOLANO HIDALGO ",
      telefonoAsistente: "432380269",
      email: "fanny.solano@municipalidadarica.cl",
      foto: "https://docs.muniarica.cl/web/media/imagenes/2023/03/logo-300.png",
    }
  ];
  private actasSesion: Array<any> = [];

  private mounted() {
    document.title = "Concejo Municipal - Municipalidad de Arica";
    this.setMenu();
    this.getActas();
  }

  private getActas() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("actas/concejo")
      .then((res) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.actasSesion = res.data;
        for (let i = 0; i < this.actasSesion.length; i++) {
          for (let j = 0; j < this.actasSesion[i].actas_sesion.length; j++) {
            this.actasSesion[i].actas_sesion[j].url_archivo =
              base + this.actasSesion[i].actas_sesion[j].url_archivo;
          }
          for (let j = 0; j < this.actasSesion[i].actas_comision.length; j++) {
            this.actasSesion[i].actas_comision[j].url_archivo =
              base + this.actasSesion[i].actas_comision[j].url_archivo;
          }
          for (
            let j = 0;
            j < this.actasSesion[i].actas_extraordinarias.length;
            j++
          ) {
            this.actasSesion[i].actas_extraordinarias[j].url_archivo =
              base + this.actasSesion[i].actas_extraordinarias[j].url_archivo;
          }
          for (let j = 0; j < this.actasSesion[i].documentos.length; j++) {
            this.actasSesion[i].documentos[j].url_archivo =
              base + this.actasSesion[i].documentos[j].url_archivo;
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.actasSesion = [];
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch((err) => {
          console.log(err);
        });
      }
    }
  }

  private async setMenu() {
    const runtimeConfig1 = await fetch("/menu.json");
    this.menu = await runtimeConfig1.json();
    for (let i = 0; i < this.menu.length; i++) {
      this.getSubMenu(this.menu[i], "concejo-municipal", 1);
    }
  }

  private getSubMenu(element: any, nombre_web: string, profundidad) {
    if (element.nombre_web == nombre_web) {
      return true;
    } else {
      if (element.sub_menu && element.sub_menu.length > 0) {
        for (let j = 0; j < element.sub_menu.length; j++) {
          if (
            this.getSubMenu(element.sub_menu[j], nombre_web, profundidad + 1)
          ) {
            if (profundidad == 2) {
              return true;
            } else {
              this.menuLateral = element.sub_menu;
              this.checkSubMenus();
              return false;
            }
          }
        }
      }
      return false;
    }
  }

  private checkSubMenus() {
    for (let i = 0; i < this.menuLateral.length; i++) {
      if (this.menuLateral[i].sub_menu.length > 0) {
        if (
          this.menuLateral[i].tipo == 2 &&
          "concejo" == this.menuLateral[i].nombre_web
        ) {
          this.menuLateral[i].expand = true;
        }
      } else {
        this.menuLateral[i].expand = false;
      }
    }
  }

  private toLink(item: any, blank: any) {
    if (item && item != "") {
      if (blank == 1) {
        window.open(item, "_blank");
      } else {
        window.open(item, "_self");
      }
    } else {
      return;
    }
  }
}
