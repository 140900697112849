








import { Component, Vue } from "vue-property-decorator";
import ConcursosPublicos from "@/components/Municipalidad/ConcursosPublicos.vue";

@Component({
  components: {
    ConcursosPublicos
  }
})
export default class ConcursosPublicosView extends Vue {
  private mounted(){
    document.title = "Concursos Públicos - Municipalidad de Arica";

    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical"; 
    canonicalLink.href = 'https://muniarica.cl/municipio/concursos-publicos'; 
    document.head.appendChild(canonicalLink);
  }
}
